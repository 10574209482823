import { Chatroom, Message } from "../model"

export class ChatroomService {
	async createChatroom(): Promise<Chatroom> {
		try {
			const res = await fetch("/api/v1/chatroom", {
				method: "POST",
			})
			const data = await res.json()
			console.log(data)
			return data
		} catch (err) {
			console.error(err)
			throw err
		}
	}

	async fetchMessages(chatroomId: string): Promise<Message[]> {
		try {
			const res = await fetch(`/api/v1/chatroom/${chatroomId}/messages`)
			const data = await res.json()
			return data
		} catch (err) {
			console.error(err)
			return []
		}
	}

	async sendMessage(chatroomId: string, message: string): Promise<void> {
		try {
			const res = await fetch(`/api/v1/chatroom/${chatroomId}/messages`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ message }),
			})
			const data = await res.json()
			console.log(data)
		} catch (err) {
			console.error(err)
		}
	}
}
