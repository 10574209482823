import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { Navigation } from "react-feather"

import { Message, MessageAuthor } from "../model"
import { ChatroomService } from "../services/chatroom"

export interface MessageProps {
	message: Message
}

function getTimeAgo(datetime: string): string {
	return moment(datetime).fromNow()
}

function TimeAgo({ datetime }: { datetime: string }) {
	const [timeAgoStr, setTimeAgoStr] = useState(getTimeAgo(datetime))

	useEffect(() => {
		setInterval(() => {
			setTimeAgoStr(getTimeAgo(datetime))
		}, 2000)
	}, [])

	return (
		<span className="text-xs text-gray-500 leading-none">{timeAgoStr}</span>
	)
}

export function BotMessage({ message }: MessageProps) {
	return (
		<div className="flex w-full mt-2 space-x-3 max-w-xs">
			<div className="flex flex-col space-y-1">
				<div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
					<p className="text-sm">{message.content}</p>
				</div>
				<TimeAgo datetime={message.createdAt} />
			</div>
		</div>
	)
}

export function UserMessage({ message }: MessageProps) {
	return (
		<div className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
			<div className="flex flex-col space-y-1">
				<div className="bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg">
					<p className="text-sm">{message.content}</p>
				</div>
				<TimeAgo datetime={message.createdAt} />
			</div>
		</div>
	)
}

export function Chatroom({
	chatroomId,
	chatroomService,
}: {
	chatroomId: string
	chatroomService: ChatroomService
}) {
	const messageListRef = useRef<HTMLDivElement>(null)
	const sendButtonRef = useRef<HTMLButtonElement>(null)
	const messageInputRef = useRef<HTMLInputElement>(null)

	const [messages, setMessages] = useState<Message[]>([])
	const [currentMessage, setCurrentMessage] = useState("")
	const [loading, setLoading] = useState(false)

	async function reloadChatroom() {
		const messages = await chatroomService.fetchMessages(chatroomId)
		setMessages(messages)
	}

	async function sendAndReload() {
		if (!currentMessage) {
			return
		}

		setLoading(true)

		try {
			setMessages([
				...messages,
				{
					author: MessageAuthor.USER,
					content: currentMessage,
					createdAt: new Date().toISOString(),
					id: "",
				},
			])
			await chatroomService.sendMessage(chatroomId, currentMessage)
			setCurrentMessage("")
			await reloadChatroom()
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		reloadChatroom()
	}, [])

	useEffect(() => {
		if (messageListRef.current) {
			messageListRef.current.scrollTop =
				messageListRef.current.scrollHeight
		}
	}, [messages])

	useEffect(() => {
		if (messageInputRef.current) {
			messageInputRef.current.focus()
		}
	})

	return (
		<div className="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl md:rounded-lg overflow-hidden">
			<div
				className="flex flex-col flex-grow h-0 p-4 overflow-auto"
				ref={messageListRef}
			>
				{messages.map((message) =>
					message.author === "bot" ? (
						<BotMessage message={message} />
					) : (
						<UserMessage message={message} />
					)
				)}
			</div>

			<div className="bg-gray-300 p-4 w-full flex flex-row space-x-2">
				<input
					className="flex flex-grow items-center h-10 w-full rounded px-3 text-sm"
					ref={messageInputRef}
					type="text"
					placeholder="Your message..."
					value={currentMessage}
					onChange={(e) => setCurrentMessage(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							sendButtonRef.current?.click()
						}
					}}
					disabled={loading}
				/>
				<button
					className="flex-shrink-0 bg-blue-600 disabled:bg-zinc-400 hover:bg-blue-700 rounded hover:shadow-md px-4 py-2 text-white"
					ref={sendButtonRef}
					disabled={loading || !currentMessage}
					onClick={sendAndReload}
				>
					<Navigation />
				</button>
			</div>
		</div>
	)
}
