export enum MessageAuthor {
	USER = "user",
	BOT = "bot",
}

export interface Message {
	content: string
	createdAt: string
	author: MessageAuthor
	id: string
}

export interface Chatroom {
	createdAt: string
	id: string
}
