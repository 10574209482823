import React, { useState } from "react"
import { render } from "react-dom"
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom"

import { Chatroom } from "./components/chatroom"
import { ChatroomService } from "./services/chatroom"

const chatroomService = new ChatroomService()

function Chat() {
	const params = useParams()

	return (
		<div className="flex flex-col items-center w-screen min-h-screen bg-gray-100 text-gray-800 md:p-10">
			<Chatroom
				chatroomId={params.chatroomId as string}
				chatroomService={chatroomService}
			/>
		</div>
	)
}

function Home() {
	const [loading, setLoading] = useState(false)

	async function start() {
		setLoading(true)
		try {
			const chatroom = await chatroomService.createChatroom()
			window.location.href = `/chatroom/${chatroom.id}`
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="flex flex-col items-center w-screen min-h-screen bg-gray-100 text-gray-800 md:p-10">
			<button
				className="mt-10 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
				disabled={loading}
				onClick={start}
			>
				Start
			</button>
		</div>
	)
}

render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/chatroom/:chatroomId" element={<Chat />} />
		</Routes>
	</BrowserRouter>,
	document.getElementById("app")
)
